.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.nav-logo {
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;
  font-family: 'Source Code Pro', monospace;
  font-weight: bolder;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  font-weight: bolder;
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
  font-family: 'Source Code Pro', monospace;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
}


.nav-item .active {
  border: 0px;
  border-bottom: 4px solid;
  border-image: linear-gradient(70deg, rgb(254, 107, 139) 30%, rgb(255, 142, 83) 90%);
  border-image-slice: 1;
  margin: auto;
  text-decoration: none;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 120%;
    left: -120%;
    position: absolute;
    top: 50px;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #ffffff;
    left: -50px;
    position: absolute;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: -0.4em;
    right: -0.4em;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fe6b8b;
  }
}