@import "src/styles/colors";

h1 {
  font-size: 4em;
  background: -webkit-linear-gradient($pink 30%,$orange 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
  text-align: center;
  margin: 0;
}
h3 {
  display:inline-block;
  background: linear-gradient($pink 30%,$orange 90%);
  background-size:100% 4px;
  background-position:bottom 0 left 0,bottom 8px left 0;
  background-repeat:no-repeat;
  
}
h4 {
  font-size: 1em;
  margin: 0;
}
p {
  margin: 0.4em;
  font-size: 14pt;
}
a {
  text-decoration: none;
  color: #252525;
}