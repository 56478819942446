
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-5px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(5px);
  }
}

@keyframes radiate {
  0%, 100% {
    text-shadow: 0 0 10px rgba(255,255,255, 0.5);
  }
  50% {
    text-shadow: 0 0 30px rgba(255,255,255, 0.5), 0 0 30px rgba(255,255,255, 0.7);
  }
}


.shake {
  font-size: 2vw;
}

.shake:hover {
  animation: shake 0.5s;
  animation-iteration-count: 1;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.specialStyleForMarch15 {
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  transition: 0.5s;
  border-radius: 10px; /* Adjust as needed */
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%' dy='0.35em' x='50%' text-anchor='middle'>💛</text></svg>") 16 0, auto;
}

.specialStyleForMarch15:hover {
  animation: animate 8s linear infinite;
}

.specialStyleForMarch15:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 15px; /* Adjust as needed */
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
}

.specialStyleForMarch15:hover:before {
  filter: blur(20px);
  opacity: 1;
  animation: animate 8s linear infinite;
}

@keyframes wave-animation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.wave {
  display: inline-block;
  animation-name: wave-animation;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  /* Add a slight delay to each character */
  animation-delay: calc(0.1s * var(--char-index));
}
