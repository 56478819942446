.projects {
  min-height: 90vh;
  margin: 2em;
  a {
    font-weight: bolder;
  }
}

@media screen and (max-width: 960px) {
  .projects {
    margin: 1em;
  }
}
