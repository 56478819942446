.extra {
  text-align: center;
  padding-bottom: 2rem;
}

@media screen and (max-width: 962px) {
  p {
    line-height: 1;
    font-size: 10pt;
  }
}
