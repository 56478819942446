.navLinksContainer {
  display: grid;
  line-height: 1;
}

@media screen and (max-width: 962px) {
  .navLinksContainer {
    p {
      line-height: 1;
      font-size: 10pt;
    }
  }
}