.footer {
  background-color: #ffffff;
  width: 100%;
  padding-bottom: 1em;
  text-align: center;
  vertical-align: baseline;
  h3 {
    font-size: 1em;
  }
}

@media screen and (max-width: 962px) {
  .footer {
    p {
      line-height: 1.5;
      font-size: 10pt;
    }
  }
}
