@import "src/styles/colors";

.homepage {
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-left: 0em;
  padding-right: 4em;
  text-align: center;
  p {
    line-height: 1.5;
  }
  .image {
    text-align: right;
  }
  .circle {
    width: 8px;
    height: 8px;
    margin: 0.1em;
    border-radius: 10px;
    display: inline-block;
  }
}
@media screen and (max-width: 962px) {
  .homepage {
    padding-top: 2rem;
    padding-bottom: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: none;
    align-items: none;
    min-height: 0vh;
    h1 {
      font-size: 48pt;
    }
    h1,
    .image {
      text-align: center;
    }
    .icons {
      text-align: center;
      position: relative;
      top: -5em;
    }
    .text {
      text-align: center;
      position: relative;
      top: -5em;
    }
  }
  p {
    line-height: 1.5;
    font-size: 10pt;
  }
}
